/* eslint-disable no-restricted-syntax */
import { useCallback, useMemo } from "react";

import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useForm, FormProvider, Controller } from "react-hook-form";

import map from "lodash/map";
import clsx from "clsx";
import Select from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "libphonenumber-js/min";

import { QUERY_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/query";
import { UPDATE_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/mutation";

import Input from "../../../../../components/Input";
import BrotherPrinterSetting from "./BortherPrinterSetting";

import { useCurrentHasPlan } from "../../../../../components/PlanBlock";
import useHasRole from "../../../../../lib/hooks/useHasRole";
import { CUSTOMIZABLE_ANIMATIONS_RECEPTIONS } from "../../../../../config/const/common";

interface FormValues extends IDigitalReceptionSettings {}

const overviewFieldsOptions: OverviewField[] = [
  "NAME",
  "TYPE",
  "CHECKIN_TIME",
  "HOST",
  "CHECKOUT",
];

const GeneralSettings = () => {
  const methods = useForm<FormValues>({
    shouldUnregister: false,
  });

  const { screenId } = useParams<{ screenId: string }>();

  const { t } = useTranslation(["screens", "common"]);

  const isFree = useCurrentHasPlan(["free"]);

  const isAdmin = useHasRole(["ADMIN"]);

  const { data } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_SETTINGS, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception: { settings } }) => {
      if (!settings) {
        return;
      }

      methods.reset(settings);
    },
  });

  const template = data?.digitalReception?.template;

  const isAnimationsCustomizable = useMemo(
    () => CUSTOMIZABLE_ANIMATIONS_RECEPTIONS.includes(template || ""),
    [template],
  );

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_SETTINGS, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_SETTINGS,
        variables: { id: screenId },
      },
    ],
  });

  const overviewFieldsSelectOptions = map(overviewFieldsOptions, (v) => ({
    value: v,
    label: t(`screens:screen.settings.overview.fieldOptions.${v}`),
  }));

  const onSubmit = useCallback(
    async (settings: FormValues) => {
      const {
        support,
        receptionistPhone,
        dispenser,
        deliveryReceiver,
        overview,
        animations,
        printing,
        wifi,
      } = settings;

      const { securityType, ssid, password } = wifi;

      if (
        (securityType || ssid || password) &&
        !(securityType && ssid && password)
      ) {
        toast.error<string>(
          t("screens:screen.screenRoute.toast.incompleteWifiData"),
        );
        return;
      }

      onUpdate({
        variables: {
          input: {
            id: screenId,
            settings: {
              support: {
                email: support?.email || null,
              },
              receptionistPhone: {
                primary: receptionistPhone?.primary || null,
                backup: receptionistPhone?.backup || null,
              },
              dispenser: {
                id: dispenser.id || null,
                socketUrl: dispenser.socketUrl || null,
              },
              deliveryReceiver: {
                fullName: deliveryReceiver.fullName || null,
                email: deliveryReceiver.email || null,
                phone: deliveryReceiver.phone || null,
              },
              overview: {
                code: overview?.code || null,
                fields: overview?.fields || [],
              },
              animations: {
                enabled: animations?.enabled,
              },
              printing: {
                enabled: printing?.enabled,
              },
              wifi:
                securityType && ssid && password
                  ? {
                      securityType,
                      ssid,
                      hiddenSsid: wifi.hiddenSsid || false,
                      password,
                    }
                  : null,
            },
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));
      });
    },
    [onUpdate, t, screenId],
  );

  return (
    <div className="container-fluid">
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            {isAnimationsCustomizable && isAdmin && (
              <div className="form-group mb-4">
                <h6>{t("screens:screen.settings.animations.title")}</h6>
                <Controller
                  name="animations.enabled"
                  render={({ field: { value, name, onChange, ...rest } }) => {
                    return (
                      <div className="form-check my-3">
                        <input
                          id={name}
                          {...rest}
                          type="checkbox"
                          className="form-check-input"
                          checked={value === true}
                          onChange={() => onChange(!value)}
                        />
                        <label className="form-check-label" htmlFor={name}>
                          {t("screens:screen.settings.animations.enabled")}
                        </label>
                      </div>
                    );
                  }}
                />
              </div>
            )}

            <BrotherPrinterSetting />

            <div className="form-group mb-4">
              <h6>{t("screens:screen.settings.support.title")}</h6>
              <div className="form-group">
                <label htmlFor="supportEmail">
                  {t("screens:screen.settings.support.email")}
                </label>
                <Input
                  name="support.email"
                  autoComplete="email"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group mt-4 mb-4">
              <h6>{t("screens:screen.settings.receptionistPhone.title")}</h6>
              <div className="form-group">
                <label htmlFor="phone">
                  {t("screens:screen.settings.receptionistPhone.primaryPhone")}
                </label>
                <Input
                  name="receptionistPhone.primary"
                  className="form-control"
                  rules={{
                    validate: {
                      invalid: (value) => {
                        if (!value) {
                          return true;
                        }
                        return (
                          isValidPhoneNumber(value) ||
                          t("employee:input.error.phone.format")
                        );
                      },
                    },
                  }}
                  disabled={isFree}
                />
                <small className="form-text text-muted small">
                  {t("employee:input.label.phone-help")}
                </small>
              </div>
            </div>

            <div className="form-group mt-4 mb-4">
              <h6>{t("screens:screen.settings.deliveryReceiver.title")}</h6>
              <div className="form-group">
                <label htmlFor="deliveryFullName">
                  {t("screens:screen.settings.deliveryReceiver.fullName")}
                </label>
                <Input
                  name="deliveryReceiver.fullName"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="deliveryEmail">
                  {t("screens:screen.settings.deliveryReceiver.email")}
                </label>
                <Input
                  name="deliveryReceiver.email"
                  autoComplete="email"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="deliveryPhone">
                  {t("screens:screen.settings.deliveryReceiver.phone")}
                </label>
                <Input
                  name="deliveryReceiver.phone"
                  className="form-control"
                  rules={{
                    validate: {
                      invalid: (value) => {
                        if (!value) {
                          return true;
                        }
                        return (
                          isValidPhoneNumber(value) ||
                          t("employee:input.error.phone.format")
                        );
                      },
                    },
                  }}
                  disabled={isFree}
                />
                <small className="form-text text-muted small">
                  {t("employee:input.label.phone-help")}
                </small>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group mb-4">
              <h6>{t("screens:screen.settings.overview.title")}</h6>
              <div className="form-group">
                <label htmlFor="overviewCode">
                  {t("screens:screen.settings.overview.code")}
                </label>
                <Input
                  name="overview.code"
                  type="password"
                  className="form-control"
                  maxLength={6}
                  rules={{
                    minLength: {
                      value: 6,
                      message: t(
                        "screens:screen.settings.overview.error.minLength",
                        {
                          count: 6,
                        },
                      ),
                    },
                    maxLength: {
                      value: 6,
                      message: t(
                        "screens:screen.settings.overview.error.maxLength",
                        {
                          count: 6,
                        },
                      ),
                    },
                    validate: {
                      format: (value) => {
                        if (!value) {
                          return true;
                        }
                        return (
                          /^[0-9]+$/.test(value) ||
                          t("screens:screen.settings.overview.error.format")
                        );
                      },
                    },
                  }}
                  disabled={isFree}
                />
              </div>
              <Controller
                name="overview.fields"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div className="form-group">
                    <label htmlFor="overviewFields">
                      {t("screens:screen.settings.overview.fields")}
                    </label>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={overviewFieldsSelectOptions}
                      onChange={(nextValue) => {
                        const nextFields: OverviewField[] = map(
                          nextValue,
                          "value",
                        );

                        onChange(nextFields);
                      }}
                      value={map(value, (value) => ({
                        value,
                        label: t(
                          `screens:screen.settings.overview.fieldOptions.${value}`,
                        ),
                      }))}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                    />
                    {!!error && (
                      <div className="invalid-feedback">{error.message}</div>
                    )}
                  </div>
                )}
              />
            </div>

            <div className="form-group mb-4">
              <h6>{t("screens:screen.settings.dispenser.title")}</h6>
              <div className="form-group">
                <label htmlFor="dispenserId">
                  {t("screens:screen.settings.dispenser.id")}
                </label>
                <Input
                  name="dispenser.id"
                  className="form-control"
                  disabled={isFree}
                />
              </div>
              <div className="form-group">
                <label htmlFor="dispenserSocketUrl">
                  {t("screens:screen.settings.dispenser.socketUrl")}
                </label>
                <Input
                  name="dispenser.socketUrl"
                  className="form-control"
                  disabled={isFree}
                />
              </div>
            </div>
            <div className="form-group mt-4 mb-4">
              <h6>{t("screens:screen.settings.wifi.title")}</h6>
              <div className="form-group">
                <label htmlFor="wifi.securityType">
                  {t("screens:screen.settings.wifi.securityType")}
                </label>
                <select
                  {...methods.register("wifi.securityType")}
                  id="wifi.securityType"
                  className="custom-select"
                >
                  <option value="" />
                  <option value="WPA">WPA</option>
                  <option value="WEP">WEP</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="wifi.ssid">
                  {t("screens:screen.settings.wifi.ssid")}
                </label>
                <Input
                  id="wifi.ssid"
                  name="wifi.ssid"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <Controller
                  name="wifi.hiddenSsid"
                  render={({ field: { value, name, onChange, ...rest } }) => {
                    return (
                      <div className="form-check my-3">
                        <input
                          id={name}
                          {...rest}
                          type="checkbox"
                          className="form-check-input"
                          checked={value === true}
                          onChange={() => onChange(!value)}
                        />
                        <label className="form-check-label" htmlFor={name}>
                          {t("screens:screen.settings.wifi.hiddenSsid")}
                        </label>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="wifi.password">
                  {t("screens:screen.settings.wifi.password")}
                </label>
                <Input
                  id="wifi.password"
                  name="wifi.password"
                  type="password"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default GeneralSettings;
