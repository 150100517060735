import React, { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { useDebounce } from "react-use";
import { pickBy } from "lodash";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { MUTATION_UPDATE_BADGE_TEMPLATE_OPTIONS } from "../../../../config/graphql/mutation";
import { QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS } from "../../../../config/graphql/query";

import { useClientId } from "../hooks/useClient";
import { upload } from "../../../../lib/hooks/useSocket";
import useHasRole from "../../../../lib/hooks/useHasRole";
import { useBrotherPrinterTemplateOptions } from "../../../../lib/hooks/useBrotherPrinterTemplateOptions";

import ImageCropPicker from "../../../../components/ImageCropPicker";

const DEFAULT_BADGE_OPTIONS: IBadgeOptions = {
  showFullName: true,
  showCompany: true,
};

const BrotherPrinterTemplateOption = React.memo(
  ({
    name,
    label,
    disabled,
    onChangePreview,
  }: {
    name: string;
    label: string;
    disabled?: boolean;
    onChangePreview?: (name: string, checked: boolean) => void;
  }) => {
    console.log({ disabled });
    return (
      <Controller
        name={name}
        render={({ field: { value, onChange, ...rest } }) => {
          return (
            <div className="form-check form-check-inline">
              <input
                {...rest}
                id={name}
                disabled={disabled}
                type="checkbox"
                className="form-check-input"
                checked={value === true}
                onChange={(e) => {
                  onChange(e);
                  onChangePreview?.(name, e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor={`${name}`}>
                {label}
              </label>
            </div>
          );
        }}
      />
    );
  },
);

const ToggleBrotherPrinterButton: React.FC<{
  disable?: boolean;
  onClick: () => void;
}> = React.memo(({ disable, onClick }) => {
  return (
    <button
      className={`btn btn-primary ${disable ? "btn-danger" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        onClick?.();
      }}
    >
      {disable ? "Disable" : "Enable"} Brother Printer
    </button>
  );
});

const BrotherPrinter: React.FC = () => {
  const { t } = useTranslation(["client", "common"]);
  const clientId = useClientId();
  const isAdmin = useHasRole(["ADMIN"]);

  const badgeIFrame = useRef<HTMLIFrameElement>(null);
  const badgeForm = useForm<IBadgeOptions>({
    shouldUnregister: true,
    defaultValues: DEFAULT_BADGE_OPTIONS,
  });

  const brotherPrinterTemplateOptions = useBrotherPrinterTemplateOptions({
    clientId,
    onCompleted: (data) => {
      badgeForm.reset(data.brotherPrinterTemplateOptions);
    },
  });

  const badgeFormValues = badgeForm.watch();
  const imageId = brotherPrinterTemplateOptions?.logo || "";

  const [badgeQueryString, setBadgeQueryString] = useState<string>(
    qs.stringify(badgeForm.getValues()),
  );

  useDebounce(
    () => {
      setBadgeQueryString(() =>
        qs.stringify(
          pickBy(
            badgeFormValues,
            (item) => item === true && typeof item === "boolean",
          ),
        ),
      );
    },
    500,
    [badgeFormValues],
  );

  const [onUpdate] = useMutation<{ input: IBadgeOptions & { client: string } }>(
    MUTATION_UPDATE_BADGE_TEMPLATE_OPTIONS,
    {
      refetchQueries: [
        {
          query: QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS,
          variables: { client: clientId },
        },
      ],
    },
  );

  const onUpload = useCallback(async (logo: IBadgeOptions["logo"]) => {
    let input: { logo?: string } = {};

    if (logo?.file) {
      await upload([logo]).then((r) => {
        input = {
          ...input,
          logo: r[0].id,
        };
      });
    }

    return input;
  }, []);

  const onSubmit = useCallback(
    async (values: IBadgeOptions) => {
      const uploadedImages = await onUpload(values?.logo);
      try {
        await onUpdate({
          variables: {
            input: {
              client: clientId,
              enabled: true,
              ...DEFAULT_BADGE_OPTIONS,
              ...values,
              ...(uploadedImages?.logo ? { logo: uploadedImages?.logo } : {}),
            },
          },
        });

        toast.success<string>(t("screens:client.brotherPrinter.toast.success"));
      } catch (error) {
        toast.success<string>(t("screens:client.brotherPrinter.toast.error"));
      }
    },
    [clientId, onUpdate, onUpload, t],
  );

  const onDisableReception = useCallback(
    () =>
      onUpdate({
        variables: {
          input: {
            client: clientId,
            enabled: false,
          },
        },
      }),
    [onUpdate, clientId],
  );

  if (!brotherPrinterTemplateOptions?.enabled) {
    return (
      <div
        className="tab-pane fade show active"
        id="badge-printer"
        role="tabpanel"
      >
        <ToggleBrotherPrinterButton
          onClick={() => {
            onSubmit({ enabled: true });
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="tab-pane fade show active"
      id="badge-printer"
      role="tabpanel"
    >
      <FormProvider {...badgeForm}>
        <form className="row" onSubmit={badgeForm.handleSubmit(onSubmit)}>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <h5>
                {t(
                  "screens:client.brotherPrinter.form.title",
                  "Badge Template Options",
                )}
              </h5>
              <div className="form-group d-flex flex-column">
                <BrotherPrinterTemplateOption
                  name="showImage"
                  disabled
                  label={t(
                    "screens:brotherPrinter.visitorImage",
                    "Visitor Image",
                  )}
                />
                <BrotherPrinterTemplateOption
                  name="showFullName"
                  label={t(
                    "screens:brotherPrinter.visitorName",
                    "Visitor Name",
                  )}
                />
                <BrotherPrinterTemplateOption
                  name="showEmployeeName"
                  label={t("screens:brotherPrinter.hostName", "Host Name")}
                />
                <BrotherPrinterTemplateOption
                  name="showCompany"
                  label={t(
                    "screens:brotherPrinter.hostCompany",
                    "Host Company",
                  )}
                />
                <BrotherPrinterTemplateOption
                  name="showCheckinDate"
                  label={t(
                    "screens:brotherPrinter.visitorCheckinDate",
                    "Date of check-in",
                  )}
                />
                <BrotherPrinterTemplateOption
                  name="showLogo"
                  label={t(
                    "screens:brotherPrinter.hostCompanyLogo",
                    "Host Company Logo",
                  )}
                />
                <BrotherPrinterTemplateOption
                  name="showAccessCode"
                  label={t("screens:brotherPrinter.qrCode", "QR Code")}
                />
              </div>
              <Controller
                name="logo"
                render={({ field: { name, value, onChange } }) => (
                  <div className="form-group">
                    <label htmlFor={name}>
                      {t("client:brotherPrinter.logo.label", "Logo")}
                    </label>
                    <ImageCropPicker
                      name={name}
                      aspect={1080 / 545}
                      value={value}
                      onChange={(fileBase64) => onChange(fileBase64)}
                    />
                    <small className="form-text text-muted">
                      {t("screens:screen.theme.image.description")}
                    </small>
                  </div>
                )}
              />
            </div>
            {/* <pre>{JSON.stringify(badgeForm.getValues(), null, 2)}</pre> */}
          </div>
          <div className="col-12 col-md-6">
            <iframe
              key={`badge-print-preview?${badgeQueryString}${
                imageId ? `&logo=${imageId}` : ""
              }`}
              ref={badgeIFrame}
              title="badge-print-preview"
              className="border border-dark"
              src={`${process.env.REACT_APP_API_URL}/api/v1/brother-printer/print/?${badgeQueryString}`}
              style={{
                width: "350px",
                height: "212px",
              }}
            />
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary mr-2" />
            {isAdmin ? (
              <ToggleBrotherPrinterButton
                disable
                onClick={onDisableReception}
              />
            ) : null}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(BrotherPrinter);
