import { useQuery } from "@apollo/client";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS } from "../../../../../config/graphql/query";
import { useClientId } from "../../../../Clients/Client/hooks/useClient";

const useBrotherPrinterTemplateOptionsQuery = (client = "") =>
  useQuery<{
    brotherPrinterTemplateOptions: IBadgeOptions;
  }>(QUERY_BROTHER_PRINTER_TEMPLATE_OPTIONS, {
    skip: !client,
    variables: { client },
  });

const BrotherPrinterSetting = () => {
  const clientId = useClientId();
  const { t } = useTranslation(["screens"]);

  const { data, loading } = useBrotherPrinterTemplateOptionsQuery(clientId);
  const { enabled } = data?.brotherPrinterTemplateOptions || {};

  if (!data?.brotherPrinterTemplateOptions || !enabled) {
    return null;
  }

  return (
    <Controller
      name="printing.enabled"
      render={({ field: { value = false, name, onChange, ...rest } }) => (
        <div className="form-group mt-4 mb-4">
          <h6>Brother Printer</h6>
          <div className="form-check my-3">
            <input
              disabled={loading || !enabled}
              id={name}
              {...rest}
              type="checkbox"
              className="form-check-input"
              checked={value === true}
              onChange={() => onChange(!value)}
            />
            <label className="form-check-label" htmlFor={name}>
              {t("screens:screen.settings.printing.enabled")}
            </label>
          </div>
        </div>
      )}
    />
  );
};

export default BrotherPrinterSetting;
